import { v4 as uuid } from 'uuid';

export interface IDBObjectArgs {class_name:string, ref:string}

export class DBObject {
  class_name:string;
  isNew:boolean;
  ref?:string;
  to1C: boolean; 

  constructor({ class_name, ref }:IDBObjectArgs) {
    this.class_name = class_name;
    this.ref = ref === 'new' ? uuid() : ref;
    this.isNew = ref === 'new';
    this.to1C = false;
  }

  get _id() {
    return `${this.class_name}|${this.ref}`;
  }

  export() {
    return {
      _id: this._id,
      ref: this.ref,
      class_name: this.class_name,
      to1C: true,
    };
  }

  load<T extends {ref:string} = any>(data:T) {
    this.ref = data?.ref;
    this.isNew = false;
  }

}

// import CustomStore from 'devextreme/data/custom_store';
import { gql } from 'apollo';
import { Order } from 'models/documents/order/order';
import { calculateStatus } from 'utils/calculate-order-status';
import { createDataSource } from 'utils/gql-datasource';

const dsGetOrders = gql(`query dsGetOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, 
  $sort: sort, $requireTotalCount: Boolean!,$userData:JSON
) {
  totalcount: buyers_orders(
    ref: $ref, jfilt: $jfilt, totalCount: 1, userData: $userData
  ) @include(if: $requireTotalCount) {
    totalcount
  }

  list: buyers_orders(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, 
    sort: $sort, userData: $userData
  ) {
    ref 
    number_doc 
    date 
    doc_amount 
    caption 
    protected 
    shipped
    paid
    proj
    pay_kind
    note 
    partner { ref name }
    services{ nomRef, quantity, amount, row, msto }
    isSubContract
    ext_json
  }
}`);

export const dsOrders = createDataSource<Order>(dsGetOrders, {
  processData: (data:Order[]) => (
    data?.map((row) => ({
      ...row,
      isSubContract: !!row.isSubContract,
      status: calculateStatus(row) }))
  ),
});

dsOrders.on('loading', (options:any) => {
  options.userData = dsOrders.userData;
});




import { useLazyQuery } from "@apollo/client";
import { gql} from "apollo";
import { useState } from "react";
import { IOrderForPay } from "types/datatypes/IOrderForPay";

export const GET_BLANK_ORDER = gql(`query GetBlanksOrder($ref: String) {
    blanks(ref: $ref) {
      _id ref number_doc date doc_amount note trust_doc
      partner { ref name }
      pos_blank { nomType quantity amount price in_doc_number in_doc_date file_lab file_1c }
    }
  }`);

interface useGetOrderProps {
  initValue:IOrderForPay;
  afterLoad?: (data:IOrderForPay)=>Promise<IOrderForPay>
}

export const useGetBlankOrder = ({initValue, afterLoad}:useGetOrderProps)=>{
  const [doc, setDoc] = useState(initValue);
  const [error, setError] = useState<any>();
  const [getOrderFunction] = useLazyQuery(GET_BLANK_ORDER)
  const fetcher = async (id:string):Promise<IOrderForPay> => {
     if (id==='new') return doc;
      const {data, error} = await getOrderFunction({variables: { ref: id }});
      
      if (error) setError(error);

      const _doc = data?.blanks?.[0] as IOrderForPay ?? initValue;
      setDoc(_doc);
      return _doc;
  }

  return [fetcher, {doc, error}];
}; 
  
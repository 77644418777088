import { useQuery } from '@apollo/client';
import { gql } from 'apollo';
import { useCallback, useMemo } from 'react';
import { ILab } from 'types/datatypes/ILab';

const DEFAULT_PAYMENT_TYPE_ID = '000000002';
const T300_PAYMENT_TYPE_ID = '000000005';
const ONLINE_PAYMENT_TYPE_ID = '000000004';
const TERMINAL_PAYMENT_TYPE_ID = '000000003';
const NOCASH_PAYMENT_TYPE_ID = '000000002';

const payKindIds = {
    online: ONLINE_PAYMENT_TYPE_ID,
    default: DEFAULT_PAYMENT_TYPE_ID,
    t300: T300_PAYMENT_TYPE_ID,
    terminal: TERMINAL_PAYMENT_TYPE_ID,
    nocash: NOCASH_PAYMENT_TYPE_ID
  };

interface IPayKind  {
  ref?: string;
  id?: string;
  name?: string;
  pay_form?:string;
  disabled?: boolean;
}  

const PAYKINDS = gql(`query getPayKinds { getPayKindes { ref id pay_form name } }`);

export const usePayKinds = () => {
  const { data } = useQuery(PAYKINDS, { fetchPolicy: 'cache-first' });
  const payKinds:IPayKind[] = useMemo(()=>data?.getPayKindes?.map(r => ({...r})) ?? [], [data]);

  const payKindByRef = (ref:string) => payKinds?.find((r) => r.ref === ref);
  const byId = useCallback((id:string) => payKinds?.find(p => p.id === id),[payKinds]);
  const availablePayKinds = useCallback((lab:ILab, { disableT300 } = { disableT300: false }) => {
    //disable T300 in def list
    let availPayKinds = payKinds.map( (r) => ({
          ...r,
          disabled: r.id === payKindIds.t300
         }));
    // use payKind list from lab settings in 1C if available
    const labPayKinds = lab?.pay_kinds?.map((r) => r.pay_kind) ?? [];
    if (labPayKinds.length) {
      availPayKinds = payKinds.map((r) => ({
        ...r,
        disabled: !labPayKinds.includes(r.ref!) || (r.id === payKindIds.t300 && disableT300),
      }));
    }
    return availPayKinds;
  }, [payKinds]);

  const payKindByName = useMemo(()=>({
    default: byId(payKindIds.default) ?? payKinds?.[0],
    online: byId(payKindIds.online),
    t300: byId(payKindIds.t300),
    terminal: byId(payKindIds.terminal),
    nocash: byId(payKindIds.nocash)
  }),[byId, payKinds]);

  return {
      payKinds, availablePayKinds, payKindIds, payKindByName, payKindByRef
   };
};

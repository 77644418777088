/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from 'app-navigation';
import * as events from 'devextreme/events';
import { useNavigation } from 'contexts/navigation';
import { useScreenSize } from 'utils/media-query';
import { useAuth } from 'contexts';
import './side-navigation-menu.scss';
import { Footer } from 'components';
import { MenuItem } from './navigation-menu-item';
import { matchRole } from 'utils/roles';
import styles from './menu.module.scss';
import { Roles } from 'types/datatypes/IUser';


interface SideNavigationMenuProps {
  children: React.ReactNode;
  selectedItemChanged: (e: any) => void;
  openMenu: (e: any) => void;
  compactMode: boolean;
  onMenuReady: (e: any) => void;
}

interface NavigationItem {
  path?: string;
  roles?: Roles;
  items?: NavigationItem[];
  visible?: boolean;
  expanded?: boolean;
}

export const SideNavigationMenu: React.FC<SideNavigationMenuProps> = (props) => {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
  } = props;

  const { isLarge } = useScreenSize();
  const { user } = useAuth();

  const normalizePath = (): NavigationItem[] => navigation.map((item: NavigationItem) => {
    if (item.path && !/^\//.test(item.path)) item.path = `/${item.path}`;
    return { ...item, expanded: isLarge };
  });

  const filterByRoles = (): NavigationItem[] => normalizePath().map((item: NavigationItem) => {
    if (!matchRole(user.roles, item?.roles)) item.visible = false;
    if (item.items) item.items = item.items.map((subItem: NavigationItem) => {
      if (subItem?.roles?.length && !matchRole(user.roles, subItem?.roles)) subItem.visible = false;
      return subItem;
    });
    return item;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = useMemo(filterByRoles, []);

  const { currentPath } = useNavigation();

  const treeViewRef = useRef<TreeView>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const getWrapperRef = useCallback((element: HTMLDivElement) => {
    const prevElement = wrapperRef.current;
    if (prevElement) events.off(prevElement, 'dxclick');
    wrapperRef.current = element;
    events.on(element, 'dxclick', (e:any) => openMenu(e));
  }, [ openMenu ]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) return;
    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }
    if (compactMode) treeView.collapseAll();
  }, [ currentPath, compactMode ]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          className={styles.treeview}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
          itemRender={(data) => (<MenuItem {...data}/>)}
        />
      </div>
      <div className={'menu-footer'}><Footer compactMode={compactMode}/></div>

    </div>
  );
};

import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDocChangeListener } from '.';
import { NEW_DOC_ID } from 'app-constants';
import { showError, showSuccess } from 'utils/notify';
import { messages } from 'messages';
import lodash from 'lodash';
import { TDataBaseObjectClasseNames } from 'types/otkTypes';

const { isEqual } = lodash;

interface HookState<T> {
  loading: boolean,
  data: T,
}

interface useDocProps<T> {
  className: TDataBaseObjectClasseNames;
  defaultData: Partial<T>,
  isDocNew?: boolean,
  load: (id?: string) => Promise<T>,
  // update: (state: any, data: any) => T,
  update: (state: any, data: any) => void,
  afterLoad?: (state: any, data: any) => Promise<T>,
  onLoaded?: () => void,
  onUpdated?: () => void,
  onError?: (error: any) => void
}


export const useDocState = <T = any>({
  className,
  defaultData,
  isDocNew = false,
  load,
  afterLoad,
  update,
  onLoaded,
  onUpdated,
  onError,
}: useDocProps<T>): [
    T,
    (update: (prevState: T) => T) => void,
    {
      loading: boolean;
      isDocNew: boolean;
      preSave: () => void
    }
  ] => {

  const { id } = useParams();
  const navigate = useNavigate();
  isDocNew = isDocNew || (id === NEW_DOC_ID);
  const [state, setState] = useState<HookState<T>>({
    loading: true,
    data: defaultData as T,
  });

  const skip = useRef(true);

  const handleError = useCallback((e: any) => {
    if (onError) {
      onError(e);
    } else {
      showError(messages.PAGE_LOAD_FAILED);
      navigate(-1);
    }
  }, [navigate, onError]);

  const handelUpdate = useCallback(() => {
    showSuccess(messages.DOC_UPDATED);
  }, []);

  const loaded = useCallback(() => setState((prev) => ({ ...prev, loading: false })), []);

  const loadDoc = (cb: any, loadFunc: (id?: string) => Promise<T>): void => {

    loadFunc(id)
      .then(async (data) => {
        if (afterLoad) {
          data = await afterLoad(state.data, data)
            .then((newData) => {
              if (!isEqual(newData, state.data)) {
                setState((prev) => ({ ...prev, data: newData as T }));
                cb?.(newData);
              }
              loaded();
              return data;
            })
        }
        else {
        Promise.resolve(update(state.data, data))
          .then((newData) => {
            if (!isEqual(newData, state.data)) {
              setState((prev) => ({ ...prev, data: newData as T }));
              cb?.(newData);
            }
            loaded();
          }).catch((e) => handleError(e));
        }
      }).catch((e) => {
        handleError(e);
        loaded();
      });
  };

  useDocChangeListener(className, () => {
    if (skip.current) {
      skip.current = false;
    } else {
      loadDoc(onUpdated ? onUpdated : handelUpdate, load);
    }
  }, id);

  const preSave = () => {
    skip.current = true;
  };

  const setData = useCallback((update: (prevState: T) => T) => {
    setState((prev) => {
      const newData = update(prev?.data);
      if (!!newData) return ({ ...prev, data: newData })
      return prev
    });
  }, [setState]);

  useEffect(() => {
    if (isDocNew) {
      loadDoc(null, async() => (Promise.resolve(defaultData as T)));
    } else {
      loadDoc(onLoaded, load);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return [
    state.data,
    setData,
    { loading: state.loading, isDocNew, preSave }
  ];
};


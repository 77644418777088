import { useEffect, useState } from 'react';
import { PageContent } from 'components';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import { useAuth } from 'contexts';
import SelectBox from 'devextreme-react/select-box';
import { dsProjNoms } from 'datasources';
import { DX_DATE_DISPLAY_FORMAT } from 'app-constants';
import { Link } from 'react-router-dom';
import { INom } from 'types/datatypes/INom';

const otkProj = 'afe61914-f69c-11eb-8ac0-00155d000c0a';

export const PriceList = () => {
  const { projects, lab } = useAuth();
  const [ projRef, setProjRef ] = useState(otkProj);
  const [ noms, setNoms ] = useState<INom[]>([]);

  useEffect(() => {
    const dsN = dsProjNoms(projRef, lab);
      dsN.load().then( data   => {
        const newData = data.map(nom => {
          const priceRow = lab?.price.find((p:any) => p.nom === nom.ref );
          const price = priceRow?.price ?? '';
          const date = priceRow?.date ?? '';
          return { ...nom, price, date };
        });

        setNoms(newData);
        });
    }, [ projRef, lab ]);

    return (
	    <PageContent size='large'>
      <div className='otk-page-header'> Ціни (актуальні) </div>
      <div style={{
          display: 'flex',
          marginBottom: '16px',
          marginTop: '16px',
          alignItems: 'center',
          flexWrap: 'wrap'
          }}
			>
	      <div style={{ paddingRight: '16px' }}>Тип послуг</div>
        <SelectBox
          dataSource={projects.available}
          valueExpr='ref'
          displayExpr='name'
          value={projRef}
          onSelectionChanged={({ selectedItem }) => setProjRef(selectedItem.ref)}
        />
        <Link to="/policy/prices" style={{ paddingLeft: '16px' }}>Страхування</Link>
        </div>
        <DataGrid
        	dataSource={noms}
        	paging={{ enabled: false }}
        	columnChooser={{ enabled: true, mode: 'select' }}
        	showBorders={true}
					export={{
						enabled: true,
						// formats: [ 'xlsx', 'pdf' ]
					}}
        >
          <Column
            dataField='name'
            caption=''
            width={75}
            allowHiding={true}
          />
          <Column
            dataField='name_full'
            caption='Послуга'
          />
          <Column
            dataField='price'
            caption='Ціна'
            alignment='right'
            width={100}
          />
          <Column
            dataField='date'
            caption='Діє з...'
            dataType='date'
            alignment='right'
            format={DX_DATE_DISPLAY_FORMAT}
            width={150}
            allowHiding={true}
          />
        </DataGrid>
      </PageContent>
    );
};

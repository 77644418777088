import { gql } from "apollo";

export const getOrderServices = gql(`query OrderServices($ref: String) {
    buyers_orders(ref: $ref) {ref services{ nom { ref name name_full }, quantity, amount, row, msto }}}
    `);

export const updateLabReport = gql(`mutation updateLabReport($input: JSONObject) {
    setLabReport(input: $input) {
      _id
    }
  }`);

export const getLabReport = gql(`query getLabReport($ref: String) {
    lab_report(ref: $ref) {
      _id ref caption
      amount
      posted
      status
      gnumber
      vin
      number_doc
      date
      dangerous
      has_error
      error
      next_date
      validated
      vehicle_map
      blank_number
      blank_series
      spot_cashless
      warn_maybe_error
      source_report {  ref caption }
      rp
      rv
      note
      totalcount
      partner { ref name }
      invoice { ref caption date pay_kind badact_reason }
      vehicle_model { name }
      service { ref name }
    }
  }`);

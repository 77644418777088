import { useCallback, useEffect, useRef, useState } from 'react';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';
import { Menu, Tooltip } from 'devextreme-react';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { API_HOST, COLUMN_PRICE_WIDTH } from 'app-constants';
import { openDoc } from 'utils/open-doc';
import { useDocChangeListener, useProj, useMessageVars, usePayKinds} from 'hooks';
import { DoclistPageTemplate, statusCellRender } from 'components';
import { dsOrders } from 'datasources';
import { OptionsBar } from './compnents/ordersOprionsBar/optionsBar';
import { Order } from 'models/documents/order/order';
import { ItemInfo, MenuItem } from 'types/otkTypes';
import { removeMessageById } from 'contexts/messages/messages';
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid';

import styles from './order.module.scss';
import { renderMenuItem } from 'components/menuItem/menuUtils';

const printMenuItems = ({ ref, number_doc }:Order) => ({
  text: 'Друк',
  icon: '/img/pngicons/print.png',
  disabled: !(ref && number_doc),
  items: [
    {
      id: 'print',
      text: 'Рахунок',
      url: `${API_HOST}/printform/${ref}/inv`,
    },
    {
      id: 'print',
      text: 'Договір',
      url: `${API_HOST}/printform/${ref}/dog`,
    },
    {
      id: 'print',
      text: 'Договір сертифікації',
      url: `${API_HOST}/printform/${ref}/dogs`,
    },
    {
      id: 'print',
      text: 'Договір для Казначейства',
      url: `${API_HOST}/printform/${ref}/dogk`,
    },
  ],
});

const easyPayMenuItems = {
  text: 'EasyPay',
  icon: 'img/pngicons/easypay.png',
  id: 'easypay',
  items: [
    {
      beginGroup: true,
      text: 'Повідомлення про інциденти',
      icon: 'img/pngicons/conflict.png',
      id: 'easypayConflicts',
    },
    {
      beginGroup: true,
      text: 'Список в терміналі',
      icon: 'img/pngicons/list.png',
      id: 'termlist',
    },
    {
      beginGroup: true,
      text: 'Групові оплати (он-онлайн)',
      icon: 'img/pngicons/gather.png',
      id: 'easypayGroupPay',
    },
  ],
};

const reportMenuItems = {
  text: 'Звіти',
  icon: '/img/pngicons/report.png',
  id: 'reports',
  items: [
    {
      id: 'reports',
      // icon: 'money',
      text: 'Рух коштів',
      url: '/cash_flow_report',
    },
    {
      id: 'reports',
      // icon: 'money',
      text: 'Заборгованість за виконаними роботами',
      url: '/debitsbyact_report',
    },
  ],
};

export const Orders = () => {
  const [ selectedRowData, setSelectedRowData ] = useState<Order>({} as Order);
  const navigate = useNavigate();
  const gridRef = useRef<DataGrid>(null);
  const legendProtectedRef = useRef<Tooltip>(null);
  const legendIsSubContractRef = useRef<Tooltip>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const showNewOrders = !!params.get('show_new');

  const { proj } = useProj();
  const [ userData, setUserData ] = useState({});
  const { payKinds, payKindByName } = usePayKinds();
  const [ newOrders ] = useMessageVars([ 'newOrdersList' ]);

  useDocChangeListener('doc.buyers_order', () => gridRef.current?.instance.refresh(true));

  const cellShippedRender = useCallback(({data}:{data:Order}) => {
    const { shipped, pay_kind, ref } = data;
    return ((shipped && (pay_kind === payKindByName.nocash?.ref))
     ? <Link to={`/acts?trans_ref=${ref}`}>{shipped}</Link> : shipped);
  }, [payKindByName]);

  useEffect(() => {
    dsOrders.userData = userData;
    gridRef.current?.instance.refresh();
  }, [ userData ]);

  useEffect(() => {
    dsOrders.userData = undefined;
  }, []);

  return (<>
    <DoclistPageTemplate<Order>
      gridRef={gridRef}
      deps={[ proj, newOrders, payKinds, userData ]}
      pageName='Замовлення'
      onEdit={(ref) => {
        const msgId = newOrders.find((msg:any) => msg.ref === ref)?.id;
        if (msgId) removeMessageById([ msgId ]);
        navigate(`/order/${ref}`);
      }}
      dataSource={dsOrders}
      onRowPrepared={({data, rowElement, rowType}) => {
        if (data?.protected && rowType === 'data') rowElement.classList.add('otk-row-status-warning');
      }}
      onCellPrepared={({column:{dataField}, rowType, data, cellElement}) => {
        if (dataField === 'number_doc' && rowType === 'data' && newOrders.some(({ref}:any) => ref === data.ref)) {
          cellElement.classList.add(styles.cellNewOrder);
        }
        if (dataField === 'proj' && rowType === 'data' && data.isSubContract) {
          cellElement.classList.add('otk-row-cell-issubcontract');
        }
      }}
      onSelectionChanged={(e) => setSelectedRowData(e.selectedRowsData[0] || {})}
      onCellHoverChanged = {(e)=>{
        let {column,data,eventType, cellElement} = e;
        if (eventType==='mouseover') {
        if (column?.name === 'buttons' && !!data?.protected) legendProtectedRef.current?.instance.show(cellElement);
        if (column?.name === 'proj' && !!data?.isSubContract) legendIsSubContractRef.current?.instance.show(cellElement);
        }
      }}
      stateStoringEnabled={!showNewOrders}
      menuComponent={(
        <Menu
          onItemClick={({itemData}:ItemInfo) => {
            switch (itemData?.id) {
            case 'new': 
              navigate('/order/new');
              break;
            case 'print': 
              openDoc(itemData.url!);
              break;
            case 'easypayConflicts': 
              navigate('/easypays');
              break;
            case 'termlist': 
              navigate('/termlist');
              break;
            case 'easypayGroupPay': 
              navigate('/orderCollections');
              break;
            case 'reports': 
              if (itemData.url) navigate(itemData.url);
              break;
            
            default:
            }
          }}
          dataSource={[
            {
              text: 'Додати',
              icon: '/img/pngicons/plus.png',
              id: 'new',
            },
            printMenuItems(selectedRowData),
            easyPayMenuItems,
            reportMenuItems,
          ] as MenuItem[]}
          itemRender={renderMenuItem}
        />
      )}
      optionsComponent={(<OptionsBar
        onChange={(gos_code) => {setUserData((prev) => ({...prev, services: gos_code ? [ { gos_code } ] : undefined }))}}
        onChangeEdrpou = {(edrpou) => setUserData((prev) => ({...prev, edrpou: edrpou ?? undefined }))}
      />)}
    >
      <Column
        dataField='proj'
        dataType='string'
        caption='Тип'
        alignment='left'
        allowHiding={false}
        allowResizing={true}
        width={30}
        minWidth={60}
      >
        <Lookup dataSource={proj} valueExpr='ref' displayExpr='name' allowClearing={true}/>
      </Column>

      <Column
        allowSorting={false}
        allowResizing={true}
        dataField='pay_kind'
        caption='Тип опл'
        // maxWidth={130}
        minWidth={90}
        width={60}
      >
        <Lookup dataSource={payKinds} valueExpr='ref' displayExpr='name'/>
      </Column>

      <Column 
        allowSorting={true}
        allowResizing={true}
        allowHiding={false}
        dataField='doc_amount'
        caption='Сума'
        dataType='number'
        alignment='right'
        width={COLUMN_PRICE_WIDTH}
        minWidth={90}
      />

      <Column
        allowSorting={false}
        dataField='shipped'
        caption='Відвантажено'
        dataType='number'
        alignment='right'
        cellRender={cellShippedRender}
        width={90}
      />

      <Column
        allowSorting={false}
        dataField='paid'
        caption='Сплачено'
        dataType='number'
        alignment='right'
        hidingPriority={2}
        width={90}
      />
      <Column
        allowSorting={false}
        dataField='ext_json.payDateEasyPay'
        caption='T'
        dataType='boolean'
        alignment='center'
        calculateCellValue={(data:Order) => !!(data?.ext_json as any)?.payDateEasyPay}
        hidingPriority={1}
        width={30}
      />
      <Column
        cssClass='otk-cell-no-padding'
        allowSearch={false}
        allowFiltering={false}
        dataField='status'
        dataType='string'
        caption='Статус'
        alignment='center'
        hidingPriority={1}
        width={130}
        cellRender={(data:ColumnCellTemplateData) => statusCellRender(data, null, (s:any) => s.status)}
      />
          
    </DoclistPageTemplate>
    <Tooltip ref={legendProtectedRef} contentComponent={protectLegend} hideEvent='mouseleave'/>      
    <Tooltip ref={legendIsSubContractRef} contentComponent={isSubContractLegend} hideEvent='mouseleave' />      
        
    </>

  );
};

const protectLegend =()=>{
  return (<div style={{display:'flex', background:'#fffbcf'}}>
     <span className='otk-row-status-warning' style={{padding:'10px 10px 10px 20px'}}>Документ захищений</span>
     </div>)
}

const isSubContractLegend =()=>{
  return (<div style={{display:'flex', background:'#fffbcf'}}>
    <span className='otk-row-cell-issubcontract' style={{padding:'10px 10px 10px 20px'}}>Генпідрядний</span>
    </div>)
}

import { gql } from "apollo";

export const GET_PRICE_ORDER =  gql(`query getPriceOrder($ref: String) {
  priceorder(ref: $ref) {
    ref number_doc date
    start_date expiration_date
    transactions_kind
    status proj 
    note
    partner { ref name }
    goods {
      row
      newprice
      discount_percent
      quantity
      nom { ref name }
    }
  }
}`);

export const GET_PRICE_ORDERS = gql(`query dsGetPriceOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, 
  $sort: sort,
  $requireTotalCount: Boolean!
) {
  totalcount: priceorder(
    ref: $ref, jfilt: $jfilt, totalCount: 1
  ) @include(if: $requireTotalCount) {
    totalcount
  }

  list: priceorder(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, 
    sort: $sort
  ) {
    ref 
    number_doc 
    date 
    partner {
      ref 
      name
    } 
    note 
    quantity 
    status 
    transactions_kind
  }
}`);

import { useApolloClient } from '@apollo/client';
import { gql } from 'apollo';
import { useCallback, useState } from 'react';

const getPrices = gql(`query getPrices($date: String, $priceType: String) {
  prices(date: $date, priceType: $priceType) { 
    nom 
    price 
    currency 
    vat_included 
  }
}`);

export interface Price {
  nom: string;
  price: number;
}

interface UsePricesResult {
  loadPrices: (date: string, priceType?: string | null) => Promise<Price[]>;
  findPrice: (prices: Price[], ref: string) => number | undefined;
  getPrice: (ref: string) => number | undefined;
  loading: boolean;
  prices: Price[];
}

export const usePrices = (mainPriceType: string, onError?: () => void): UsePricesResult => {
  const [data, setData] = useState<Price[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const gqlClient = useApolloClient();

  const loadPrices = useCallback(async (date: string, priceType: string | null = null): Promise<Price[]> => {
    setLoading(true);
    const res = await gqlClient.query<{ prices: Price[] }>({
      query: getPrices,
      variables: { date, priceType: priceType ?? mainPriceType },
    })
      .then(({ data: { prices } }) => {
        setData(prices);
        return prices;
      })
      .catch(() => {
        onError?.();
        return [];
      });
    setLoading(false);

    return res;
  }, [gqlClient, mainPriceType, onError]);

  const prices = data;

  const findPrice = (prices: Price[], ref: string) => prices?.find((priceInfo) => priceInfo.nom === ref)?.price;

  const getPrice = (ref: string): number | undefined => findPrice(prices, ref);

  return {
    loadPrices,
    findPrice, // Find price in a price list (async update)
    getPrice, // Get price from the prices state
    loading,
    prices,
  };
};

import { gql } from 'apollo';
import { createDataSource } from 'utils/gql-datasource';

const GETPAYREPORT = gql(`
query dsPays($start_date: String!, $end_date: String!, $payKind: String!, $status: Int!, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
list: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: false, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)
totalcount: getPayReport(start_date: $start_date, end_date: $end_date, payKind:$payKind, status: $status,  requireTotalCount: $requireTotalCount, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort)
    @include(if: $requireTotalCount) 
}`);

export const dsPays = createDataSource(GETPAYREPORT, {});

dsPays.on('loading', (options:any) => {
  options.userData = dsPays?.userData;
});

import { Routes, Route, Navigate } from 'react-router-dom';
import { appInfo } from 'app-info';
import routes from 'app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from 'layouts';

export const Content = () => <SideNavBarLayout title={appInfo.title}>
  <Routes>
    {routes.map(({ path, element: Element }) => <Route
      key={path}
      path={path}
      element={<Element/>}
    />
    )}
   <Route path="*" element={<Navigate to='/dashboard' replace={true}/>} />
  </Routes >
</SideNavBarLayout>;


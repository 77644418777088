
export const roles = {
	STAGE_LAB: 'stage_lab',
	ADMIN: 'admin',
} as const;

export type Roles = typeof roles[keyof typeof roles][];

export interface IUser {
    login:string;
    name:string;
    email?:string;
    password?:string;
    branch:string;
    isBlocked:boolean;
    
    ref:string;
    _deleted:boolean;
    history?:{
        created_at?:string;
        modified_at?:string;
    },
    roles?:Roles;
  }


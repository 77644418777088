import { gql } from 'apollo';
import { createDataSource } from 'utils/gql-datasource';
const dsGetBlanksOrders = gql(`query dsGetBlankOrders($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, $sort: sort, $requireTotalCount: Boolean!) {
    totalcount: blanks(ref: $ref, jfilt: $jfilt, totalCount: 1) @include(if: $requireTotalCount) { totalcount }
    list: blanks(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, sort: $sort) {
      _id ref number_doc date doc_amount note trust_doc
      partner { ref name }
    }
  }
`);

export const dsBlanksOrders = createDataSource(dsGetBlanksOrders);

import { DOCLIST_PAGE_SIZE } from 'app-constants';
import { gqlClient } from 'gql-client';
import { filterObj } from './filtfunc';
import { splitId } from './helpers';
import { LoadOptions } from 'devextreme/data';
import { ApolloQueryResult } from '@apollo/client';
import { ApolloAnswer } from 'types/otkTypes';
import { CustomStoreExt } from 'types/datatypes/classes';

interface SortDescriptor {
  selector:string;
  desc?:boolean;
}

export const checkResponse = (response:ApolloQueryResult<any>) => {
  if (response?.errors) throw new Error(response.errors?.[0]?.message ?? '');
  return response;
};

interface PropsOption<TItem>  {
  processData?:(items:TItem[])=>void,
  // onLoading?:(options:any)=>void,
  byKeyGqlQuery?:any;
  listGqlQuery?: any;
  rawmode?:"processed" | "raw";
  loadOptions?: { 
    [x:string]:any
  };
}

export const createDataSource = <TItem = any,TKey = any>(gqlQuery:any, {
  processData,
  byKeyGqlQuery,
  listGqlQuery,
  rawmode,
  loadOptions,
}:PropsOption<TItem> = {}) => {

  const getByKey = (key:TKey):PromiseLike<TItem> => gqlClient.query({
    query: byKeyGqlQuery ?? gqlQuery,
    variables: { ref: key, requireTotalCount: false},
  })
    .then(checkResponse)
    .then(({data:{list}}) => {
      if (list?.length > 0) {
        const resp = list[0];
        if (!resp.ref && resp?._id) resp.ref = splitId(resp?._id).ref;
        return resp;
      }
      throw new Error('Empty data');
    });

  const getList = async (options:(LoadOptions<TItem>)):Promise<ApolloAnswer<TItem>> => {
    const filter = options.searchExpr && options.searchValue !== null
      ? [ options.searchExpr, options.searchOperation, options.searchValue ]
      : options.filter;
    const filterParam = filter ? filterObj(filter):null;  

    const sort = Array.isArray(options.sort) && options.sort.length > 0 ? {
      selector: (options.sort[0] as SortDescriptor).selector,
      desc: (!!(options.sort[0] as SortDescriptor).desc).toString(),
    }:undefined;

    const vars = {
      ...options?.userData?.userVars,
      requireTotalCount: !!options?.requireTotalCount,
      jfilt: filterParam,
      offset: options.skip,
      limit: options.take || loadOptions?.take || DOCLIST_PAGE_SIZE,
      sort,
      userData: ( Object.keys(store?.userData ?? {} )).length ? store?.userData : options.userData,
    };
  const resp = await gqlClient.query({
      query: listGqlQuery ?? gqlQuery,
      variables: vars
    })
    // .catch(e=> console.log('err:',e));

    //////@ts-ignore
    const { list, totalcount } = checkResponse(resp).data;
    return ({
      data: processData?.(list) ?? list ?? [],
      totalCount: totalcount?.[0]?.totalcount
    });
    
  };

const onLoading = (options:any) => {
  if (store.userOptions?.hardFilter) {
      if (options.filter) {
        options.filter = [[...store.userOptions?.hardFilter], 'and', options.filter]
      }
      else {
        options.filter = store.userOptions?.hardFilter;
      }
    }
  }

 const store =  new CustomStoreExt<TItem,TKey>({
    key: 'ref',
    byKey: (key) => getByKey(key),
    load: getList,
    onLoading: onLoading,
    loadMode:rawmode ?? 'processed',
    // loadOptions,
  });
  return store;
};

import React, { useState, createContext, useContext, useEffect, useCallback, useRef, FC } from 'react';
import { useLocation } from 'react-router';


type NavigationContextType ={
  history: string[];
  pushHistory:(path:string)=>void;
  currentPath?: string;
}
const NavigationContext = createContext<NavigationContextType>({
  history:[],
  pushHistory:(path)=>{},
});
const useNavigation = () => useContext(NavigationContext);

interface NavigationProviderProps {
  children?: React.ReactNode
}

const NavigationProvider:FC<NavigationProviderProps> = ({children}) => {
  const [ currentPath, setPath ] = useState<string>('');
  const history = useRef<string[]>([]);

  const pushHistory = useCallback((value:string)=>{
    history.current.unshift(currentPath);
    history.current = history.current.slice(0,4)
    setPath(value);
  }, [currentPath]);

  return (
    <NavigationContext.Provider value={{ history: history.current, currentPath:currentPath, pushHistory }}>
      {children}
    </NavigationContext.Provider>    
  );
};

 
function withNavigationWatcher(Component:FC<any>) {

  return function(props:any) {
    const location = useLocation().pathname;
     const { pushHistory } = useNavigation();
     useEffect(() => {
       pushHistory(location);
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [location]);
    return React.createElement(Component, props);
  };
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher,
};

import dayjs, { Dayjs } from 'dayjs';
import { DBObject, IDBObjectArgs } from '../root/base';

const CLOSED_PERIOD_DAYS = 0;

interface DocumentArgs extends IDBObjectArgs {
  date: Dayjs;
  number_doc?: string;
}

export class Document extends DBObject {

  data: object;
  date: Dayjs;
  number_doc?: string;

  constructor(args: DocumentArgs) {
    super(args);
    this.data = {};
    this.date = dayjs(args.date);
    this.number_doc = args.number_doc ?? '';
  }

  export() {
    return {
      ...super.export(),
      date: this.dateTform,
      number_doc: this.number_doc,
    };
  }


  get dateTform() {
    return this.date.format('YYYY-MM-DDTHH:mm:ss');
  }

  checkClosedPeriod(periodInDays = CLOSED_PERIOD_DAYS) {
    const today = dayjs().startOf('day');
    return this.date.add(periodInDays, 'day') < today;
  }

  async load(data:any) {
    super.load(data);
    this.date = dayjs(data.date);
    this.number_doc = data.number_doc;
    this.to1C = data.to1C;
  }
}

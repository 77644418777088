import { useEffect, useMemo } from 'react';
import { Card, CardHeader, CardContent } from 'components';
import { useQuery } from '@apollo/client';
import { CardOptions } from 'components/card/card';
import { Button, List } from 'devextreme-react';
import { useMessageVars } from 'hooks';
import { useNavigate } from 'react-router';
import styles from './new-orders.module.scss';
import dayjs from 'dayjs';
import { MenuItem } from 'devextreme-react/list';
import { DATE_DISPLAY_FORMAT } from 'app-constants';
import { gql } from 'apollo';

const getOrders = gql(`query getDashOrders($limit: Int, $jfilt: [JSON]) {
  list: buyers_orders(limit: $limit, jfilt: $jfilt) {
    ref
    date
    services {
      nom {
        name
      }
    }
  }
}`);

const date = dayjs().subtract(3, 'day').toISOString();
const LIMIT = 10;

export const NewOrdersCard = () => {
  const [ newOrdersList ] = useMessageVars( [ 'newOrdersList' ] );

  const navigate = useNavigate();

  const { data, refetch } = useQuery(getOrders, {
    variables: {
      jfilt: { fld: 'date', expr: '>=', val: date },
      limit: LIMIT,
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ newOrdersList ]);

  const newOrders = useMemo(() => (
    data?.list?.map((order) => ({
      ref: order?.ref,
      date: order?.date,
      services: order?.services?.reduce((prev, curr) => `${prev} ${curr?.nom?.name ?? ''}`, ''),
    })) ?? []
  ), [ data ]);

  const ListItem = (data:any) => (
    <div className={styles.listItem}>
      <div>{dayjs(data.date).format(DATE_DISPLAY_FORMAT)}</div>
      <div>{data.services}</div>
      <Button icon='edit' onClick={() => navigate(`/order/${data.ref}`)}/>
    </div>
  );

  return (
    <Card>
      <CardHeader
        title={'Нові Заявки за останні 3 дні'}
        subTitle={newOrders.length === LIMIT ? `>${LIMIT}` : newOrders.length}
      />
      <CardOptions>
        <Button icon='bulletlist' hint='переглянути' onClick={() => navigate('/orders?show_new=true')}/>
      </CardOptions>
      {newOrders.length > 0 &&
        <CardContent>
          <List
            dataSource={newOrders}
            itemRender={ListItem}
            focusStateEnabled={false}
            activeStateEnabled={false}
          >
            <MenuItem  text='Add to Cart' action={() => {}}/>
          </List>
        </CardContent>
      }
    </Card>
  );
};

import { FC } from 'react';
import { DateBox } from 'devextreme-react';
import { useDate } from 'hooks';
import { IDateBoxOptions } from 'devextreme-react/date-box';

import styles from './date-range.module.scss';

interface FormProps extends IDateBoxOptions {
  startValue: string;
  minStartValue?: string;
  endValue:string,
  onRangeChanged:(reange:{start:string, end:string})=>void;
  width?:string|number
}
export const DateRange:FC<FormProps> = ({
  value, startValue, endValue, onRangeChanged, width, ...props
}) => {
  const { formatDate } = useDate();
  return (
    <div className={styles.container} style={{ width }}>
      <DateBox
        {...props}
        value={startValue}
        onValueChanged={(e) => onRangeChanged({
          start: formatDate(e.value),
          end: endValue,
        })}
        min={props.minStartValue}
        max={endValue}
        width='100%'

      />
      <div className={styles.delimiter}>по</div>
      <DateBox
        {...props}
        value={endValue}
        onValueChanged={(e) => onRangeChanged({
          start: startValue,
          end: formatDate(e.value),
        })}
        onValueChange={(e) => console.log(e)}
        min={startValue}
        width='100%'
      />
    </div>
  );
};


import { List, Button } from 'devextreme-react';
import propTypes from 'prop-types';


import { SingleCard } from '../../layouts/single-card/single-card';
import { dsNews } from 'datasources';
import './news-card.scss';

interface NewsItem {
  name: string;
  main_text: string;
}

export default function newItem(item:NewsItem) {
  return (
    <div className={'news-card'} style={{ width: '100%' }}>
      <div className={'dx-card content'}>
        <div className={'header'}>
          <div className={'title'}>{item.name}</div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: item.main_text }} ></div>
      </div>
    </div>
  );
}


interface NewsProps {
  onApprove: () => void;
}

export const News = (props: NewsProps) => (
  <SingleCard title='Новини'>
    <List
      dataSource={dsNews}
      height={400}
      width={'100%'}
      pageLoadMode="scrollBottom"
      itemRender={(item: NewsItem) => newItem(item)}
      className="list-container"
    />
    <Button
      onClick={props.onApprove}
      width={'100%'}
      type={'default'}
      useSubmitBehavior={false}
      text='Підтверджую'
    >
    </Button>
  </SingleCard>
);

News.propTypes = {
  onApprove: propTypes.func,
};
